import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Avatar,
    TextField
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Flag from 'react-world-flags'; // For displaying flags
import isoLanguages from './isoLanguages'; // Import the ISO languages
import { jwtDecode } from "jwt-decode";



const TeamPage = () => {
    const [translators, setTranslators] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [openInviteDialog, setOpenInviteDialog] = useState(false);
    const [openAddUserDialog, setOpenAddUserDialog] = useState(false); // New state for manual userID dialog
    const [inviteEmail, setInviteEmail] = useState('');
    const [userID, setUserID] = useState(''); // New state for userID input
    const token = useSelector((state) => state.auth.token);
    const navigate = useNavigate();
    const [memberTeams, setMemberTeams] = useState([]); // Teams user is a member of
    const [ownedTeamTranslators, setOwnedTeamTranslators] = useState([]);
    const userId = token ? jwtDecode(token).id : null;

    useEffect(() => {
        const fetchTeamData = async () => {
            try {
                // Retrieve userId from the token
                const decodedToken = jwtDecode(token); // Assuming you're using jwtDecode
                const userId = decodedToken?.id;

                const [ownedTeamResponse, memberTeamsResponse] = await Promise.all([
                    axios.get('/api/team', {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                    axios.get('/api/team/member-teams', {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                ]);

                console.log('Owned Team Response:', ownedTeamResponse.data);
                ownedTeamResponse.data.forEach((translator) => {
                    console.log(`Owned Translator: ${translator.userId?.email || 'N/A'}, Status: ${translator.status}`);
                });

                console.log('Member Teams Response:', memberTeamsResponse.data);
                memberTeamsResponse.data.forEach((team) => {
                    const userTranslator = team.translators.find((t) => t.userId === userId);
                    console.log(`Team Name: ${team.name}, Status: ${userTranslator?.status || 'Unknown'}`);
                });

                // Ensure the responses are arrays or extract the correct properties
                setOwnedTeamTranslators(Array.isArray(ownedTeamResponse.data) ? ownedTeamResponse.data : ownedTeamResponse.data.translators || []);
                setMemberTeams(Array.isArray(memberTeamsResponse.data) ? memberTeamsResponse.data : memberTeamsResponse.data.teams || []);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching team data:', err);
                setError('Failed to load team data.');
                setLoading(false);
            }
        };

        fetchTeamData();
    }, [token]);




    const handleInviteTranslator = async () => {
        try {
            await axios.post('/api/team/invite', { email: inviteEmail }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            alert('Invitation sent successfully');
            setOpenInviteDialog(false);
            setInviteEmail('');
            setOwnedTeamTranslators([...ownedTeamTranslators, { email: inviteEmail, status: 'pending' }]);
        } catch (err) {
            console.error('Error sending invitation:', err);
            alert('Failed to send invitation');
        }
    };

    const handleAddUserToTeam = async () => {
        try {
            await axios.post('/api/team/add-user', { userId: userID }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            alert('User added successfully to the team');
            setOpenAddUserDialog(false);
            setUserID('');
            const response = await axios.get('/api/team', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setOwnedTeamTranslators(response.data);
        } catch (err) {
            console.error('Error adding user to the team:', err);
            alert('Failed to add user to the team');
        }
    };

    const handleEditProjects = (translator) => {
        navigate(`/dashboard/team/${translator._id}/edit-projects`);
    };

    const handleRemoveTranslator = async (translatorId) => {
        if (!window.confirm('Are you sure you want to remove this translator from the team?')) return;

        try {
            await axios.delete(`/api/team/translators/${translatorId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setTranslators(translators.filter((translator) => translator.userId._id !== translatorId));
            alert('Translator removed successfully');
        } catch (err) {
            console.error('Error removing translator:', err);
            alert('Failed to remove translator');
        }
    };

    const handleApproveInvite = async (teamId) => {
        try {
            console.log(`Approving invite for translator in team: ${teamId}`); // Debug log

            const response = await axios.put(
                `/api/team/member-teams/${teamId}/approve`,
                {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            console.log('Server response:', response.data); // Debug log

            // Update translator's status in the memberTeams state
            setMemberTeams((prevTeams) =>
                prevTeams.map((team) =>
                    team._id === teamId
                        ? {
                            ...team,
                            translators: team.translators.map((translator) =>
                                translator.userId === userId // Assuming you have userId available in the context
                                    ? { ...translator, status: 'active' }
                                    : translator
                            ),
                        }
                        : team
                )
            );

            alert('Invitation approved successfully!');
        } catch (err) {
            console.error('Error approving invitation:', err.response?.data || err.message); // Debug log for errors
            alert('Failed to approve invitation. Please try again.');
        }
    };



    const getInitials = (firstName, lastName) => {
        return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
    };

    const getLanguageFlag = (languageCode) => {
        const language = isoLanguages.find((lang) => lang.code.toLowerCase() === languageCode.toLowerCase());
        return language ? language.countryCode : 'UN'; // Default to UN if no match
    };

    const renderFlags = (translatedLanguages) => {
        if (!Array.isArray(translatedLanguages) || translatedLanguages.length === 0) {
            return <Flag code="UN" style={{ width: '25px' }} />; // Default to UN flag if no languages
        }

        return translatedLanguages.map((language) => {
            const flagCode = getLanguageFlag(language); // Get the flag code for each language
            return <Flag key={language} code={flagCode} style={{ width: '25px', marginRight: '5px' }} />;
        });
    };

    if (loading) {
        return (
            <Container sx={{ marginTop: '50px', textAlign: 'center' }}>
                <CircularProgress />
            </Container>
        );
    }



    return (
        <Container maxWidth="lg" sx={{ marginTop: '50px' }}>
            <Typography variant="h4" gutterBottom>
                Team Management
            </Typography>

            {/* Your Team Section */}
            <Box sx={{ marginBottom: '40px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                    <Typography variant="h6">Your Team</Typography>
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<PersonAddIcon />}
                            onClick={() => setOpenInviteDialog(true)}
                            sx={{ marginRight: '10px' }}
                        >
                            Invite Translator
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setOpenAddUserDialog(true)} // Open userID dialog
                        >
                            Add User by ID
                        </Button>
                    </Box>
                </Box>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Assigned Projects</TableCell>
                                <TableCell>Languages</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ownedTeamTranslators.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No translators have been added to the team yet.
                                    </TableCell>
                                </TableRow>
                            ) : (
                                ownedTeamTranslators.map((translator) => (
                                    <TableRow key={translator._id}>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {translator.userId?.avatarUrl ? (
                                                    <Avatar
                                                        src={`${translator.userId.avatarUrl}`}
                                                        alt={translator.userId.firstName || 'User'}
                                                        sx={{ width: 40, height: 40, marginRight: '10px' }}
                                                    />
                                                ) : (
                                                    <Avatar sx={{ width: 40, height: 40, marginRight: '10px' }}>
                                                        {getInitials(translator.userId?.firstName, translator.userId?.lastName)}
                                                    </Avatar>
                                                )}
                                                {translator.userId?.firstName && translator.userId?.lastName
                                                    ? `${translator.userId.firstName} ${translator.userId.lastName}`
                                                    : 'N/A'}
                                            </Box>
                                        </TableCell>
                                        <TableCell>{translator.userId?.email || 'N/A'}</TableCell>
                                        <TableCell>{translator.status === 'pending' ? 'Pending' : 'Active'}</TableCell>
                                        <TableCell>{translator.assignedProjectsCount || 0}</TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {renderFlags(translator.userId?.translatedLanguages)}
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            {translator.status !== 'pending' && (
                                                <>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => handleEditProjects(translator)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        color="secondary"
                                                        onClick={() => handleRemoveTranslator(translator.userId._id)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {/* Teams You're a Member Of Section */}
            <Box sx={{ marginBottom: '40px' }}>
                <Typography variant="h6" gutterBottom>
                    Teams You're a Member Of
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Team Owner</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {memberTeams.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={3} align="center">
                                        You are not a member of any team.
                                    </TableCell>
                                </TableRow>
                            ) : (
                                memberTeams.map((team) => (
                                    <TableRow key={team._id}>
                                        <TableCell>
                                            {team.owner?.company
                                                ? team.owner.company
                                                : `${team.owner?.firstName || ''} ${team.owner?.lastName || ''}`.trim() || 'N/A'}
                                        </TableCell>
                                        <TableCell>{team.status === 'pending' ? 'Pending' : 'Active'}</TableCell>
                                        <TableCell>
                                            {team.status === 'pending' ? (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleApproveInvite(team._id)}
                                                >
                                                    Approve
                                                </Button>
                                            ) : (
                                                <Typography>Active</Typography>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {/* Invite Translator Dialog */}
            <Dialog open={openInviteDialog} onClose={() => setOpenInviteDialog(false)}>
                <DialogTitle>Invite a New Translator</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Translator's Email"
                        type="email"
                        fullWidth
                        value={inviteEmail}
                        onChange={(e) => setInviteEmail(e.target.value)}
                        sx={{ marginBottom: '20px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenInviteDialog(false)}>Cancel</Button>
                    <Button onClick={handleInviteTranslator} variant="contained" color="primary">
                        Send Invitation
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Add User by ID Dialog */}
            <Dialog open={openAddUserDialog} onClose={() => setOpenAddUserDialog(false)}>
                <DialogTitle>Add User by ID</DialogTitle>
                <DialogContent>
                    <TextField
                        label="User ID"
                        type="text"
                        fullWidth
                        value={userID}
                        onChange={(e) => setUserID(e.target.value)}
                        sx={{ marginBottom: '20px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenAddUserDialog(false)}>Cancel</Button>
                    <Button onClick={handleAddUserToTeam} variant="contained" color="primary">
                        Add User
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default TeamPage;
