import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Container, Typography, Grid, TextField, Box, MenuItem, IconButton, Snackbar, Modal, SpeedDial, Alert, SpeedDialAction, SpeedDialIcon, Tooltip, LinearProgress, Button, Select, FormControl, InputLabel, Pagination, InputAdornment, Checkbox, FormControlLabel } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { CircleFlag } from 'react-circle-flags';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UndoIcon from '@mui/icons-material/Undo';
import { jwtDecode } from "jwt-decode";
import io from 'socket.io-client';
import SendIcon from '@mui/icons-material/Send';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddCommentIcon from '@mui/icons-material/AddComment';
import TranslateIcon from '@mui/icons-material/Translate';
import AnnotationPanel from './AnnotationPanel';
import NoteIcon from '@mui/icons-material/Note';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import isoLanguages from './isoLanguages'; 
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CloseIcon from '@mui/icons-material/Close';



const TranslateProject = () => {
    const { id } = useParams();
    const [originalSections, setOriginalSections] = useState([]);
    const [filteredSections, setFilteredSections] = useState([]);
    const [filteredOriginalSections, setFilteredOriginalSections] = useState([]);
    const [filteredTranslatedSections, setFilteredTranslatedSections] = useState([]);
    const [translatedSections, setTranslatedSections] = useState([]);
    const [approvedSections, setApprovedSections] = useState([]);
    const [sourceLanguage, setSourceLanguage] = useState('');
    const [targetLanguage, setTargetLanguage] = useState('');
    const [overallProgress, setOverallProgress] = useState(0);
    const [wordCount, setWordCount] = useState(0);
    const [charCount, setCharCount] = useState(0);
    const [translationService, setTranslationService] = useState('');
    const [teamMembers, setTeamMembers] = useState([]);
    const [filterNumbers, setFilterNumbers] = useState(false);
    const [filterSymbols, setFilterSymbols] = useState(false);
    const [filterNumbersAndSymbols, setFilterNumbersAndSymbols] = useState(false);
    const [projectCreator, setProjectCreator] = useState(''); 
    const [socket, setSocket] = useState(null);
    const [message, setMessage] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const token = useSelector((state) => state.auth.token);
    const [assignedTo, setAssignedTo] = useState(null);
    const chatBoxRef = useRef(null); // Create a ref to the chat box element
    const audioRef = useRef(new Audio('/assets/sounds/notification.mp3'));
    const [useTranslationMemory, setUseTranslationMemory] = useState(true);  // State for translation memory toggle
    const [autoTranslateProgress, setAutoTranslateProgress] = useState(0);
    const [isAutoTranslating, setIsAutoTranslating] = useState(false); // Indicates if translation is in progress
    const [glossaries, setGlossaries] = useState([]);
    const [selectedGlossaryId, setSelectedGlossaryId] = useState(null);
    const [selectedGlossaryTerms, setSelectedGlossaryTerms] = useState([]);
    const [filteredGlossaries, setFilteredGlossaries] = useState([]);
    const [saveProgress, setSaveProgress] = useState(0); // Track save progress
    const [isSaving, setIsSaving] = useState(false); // Track save status
    const [setSelectedSectionAnnotations] = useState([]);
    const [isAnnotationPanelOpen, setIsAnnotationPanelOpen] = useState(false);
    const [selectedSectionIndex, setSelectedSectionIndex] = useState(null);
    const [annotatedSections, setAnnotatedSections] = useState(new Set());
    const { id: projectId } = useParams(); // Rename 'id' to 'projectId' for clarity
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [copied, setCopied] = useState(false);
    const [speedDialDirection, setSpeedDialDirection] = useState('down');
    const rowRef = useRef([]);
    const [preferredAlgorithm, setPreferredAlgorithm] = useState('exact');
    const [confidenceScores, setConfidenceScores] = useState({});
    const [openGlossaryDialog, setOpenGlossaryDialog] = useState(false);
    const [newGlossaryTerm, setNewGlossaryTerm] = useState({
        source: '',
        target: '',
        context: '',
        ignore: false,
    });


    // Get language details from isoLanguages
    const getLanguageDetails = (code) => {
        return isoLanguages.find((lang) => lang.code.toLowerCase() === code.toLowerCase()) || { name: code, countryCode: 'UN' };
    };

    let currentUserId;
    if (token) {
        const decodedToken = jwtDecode(token);
        currentUserId = decodedToken.id;
    }

    const [currentPage, setCurrentPage] = useState(1);
    const sectionsPerPage = 50;
    const [currentSections, setCurrentSections] = useState([]);
    const indexOfLastSection = currentPage * sectionsPerPage;
    const indexOfFirstSection = indexOfLastSection - sectionsPerPage;
    

    // Utility functions for filtering
    const isNumber = (text) => /^[\d\s]+$/.test(text);
    const isSymbol = (text) => /^[^a-zA-Z0-9]+$/.test(text);
    const isNumberOrSymbol = (text) => /^[\d\s\W]+$/.test(text);

    // Fetch user's preferred algorithm when the component mounts
    useEffect(() => {
        const fetchUserSettings = async () => {
            try {
                const response = await axios.get('/api/user-settings', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setPreferredAlgorithm(response.data.preferredAlgorithm || 'exact'); // Fallback to 'exact'
            } catch (error) {
                console.error('Error fetching user settings:', error);
            }
        };

        fetchUserSettings();
    }, [token]);

    useEffect(() => {
        const fetchAnnotations = async () => {
            try {
                const response = await axios.get(`/api/annotations/${projectId}/annotations`, {
                    headers: {
                        Authorization: `Bearer ${token}`, // Include token in the headers
                    },
                });
                const annotations = response.data;

                // Create a set of section indices that have annotations
                const annotatedIndices = new Set(annotations.map(annotation => annotation.sectionIndex));
                setAnnotatedSections(annotatedIndices);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    console.error('Unauthorized access - please check your credentials.');
                } else {
                    console.error('Error fetching annotations:', error);
                }
            }
        };

        if (projectId) {
            fetchAnnotations();
        }
    }, [projectId, token]);

    // Handler to add a section to annotatedSections
    const handleAnnotationAdded = (sectionIndex) => {
        setAnnotatedSections((prev) => new Set(prev).add(sectionIndex));
    };

    // Apply filters and update filtered sections only when filters change
    // Apply filters and update filtered sections
    const applyFilters = useCallback(() => {
        const filteredOriginal = [];
        const filteredTranslated = [];

        originalSections.forEach((section, index) => {
            const translation = translatedSections[index] || '';

            // Apply filtering conditions
            if (filterNumbers && isNumber(section)) {
                return; // Skip numbers if the filter is active
            }
            if (filterSymbols && isSymbol(section)) {
                return; // Skip symbols if the filter is active
            }
            if (filterNumbersAndSymbols && isNumberOrSymbol(section)) {
                return; // Skip numbers and symbols if the filter is active
            }

            // If the section passes filters, add to both lists
            filteredOriginal.push(section);
            filteredTranslated.push(translation);
        });

        // Update state for filtered sections
        setFilteredOriginalSections(filteredOriginal);
        setFilteredTranslatedSections(filteredTranslated);

        // Reset pagination to the first page
        setCurrentPage(1);
    }, [
        filterNumbers,
        filterSymbols,
        filterNumbersAndSymbols,
        originalSections,
        translatedSections,
    ]);



    const handleAddGlossaryTerm = (index) => {
        // Ensure a glossary is selected
        if (!selectedGlossaryId) {
            alert('Please select a glossary before adding a term.');
            return;
        }

        const originalText = originalSections[index] || '';
        const translatedText = translatedSections[index] || '';

        // Determine if the user is the project owner
        const isProjectOwner = (typeof projectCreator === 'object'
            ? projectCreator._id
            : projectCreator?.toString()) === currentUserId?.toString();

        // Set up the new glossary term but do not submit yet
        const term = {
            source: originalText,
            target: translatedText,
            context: '',
            ignore: false,
            approved: isProjectOwner, // Set approved to false if not the project owner
        };

        // Open dialog with pre-filled term
        setNewGlossaryTerm(term);
        setOpenGlossaryDialog(true);
    };

    const handleCloseGlossaryDialog = () => {
        setOpenGlossaryDialog(false);
        setNewGlossaryTerm({ source: '', target: '', context: '', ignore: false });
    };

    const handleSubmitGlossaryTerm = async () => {
        if (!newGlossaryTerm.source) {
            alert('Source term is required!');
            return;
        }

        try {
            const response = await axios.post(`/api/glossary/${selectedGlossaryId}/terms`, newGlossaryTerm, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.status === 201) {
                alert('Glossary term added successfully!');
                // Optionally update local glossary terms
                setSelectedGlossaryTerms((prevTerms) => [...prevTerms, response.data]);
            }
        } catch (error) {
            console.error('Error adding glossary term:', error);
            alert('Failed to add glossary term.');
        } finally {
            handleCloseGlossaryDialog();
        }
    };






    useEffect(() => {
        setFilteredSections(originalSections); // Default to original sections
    }, [originalSections]);

    // Update displayed sections based on currentPage and filtered data
    useEffect(() => {
        const startIndex = (currentPage - 1) * sectionsPerPage;
        const endIndex = startIndex + sectionsPerPage;

        const sections = filteredOriginalSections.length > 0
            ? filteredOriginalSections
            : originalSections;

        const translations = filteredTranslatedSections.length > 0
            ? filteredTranslatedSections
            : translatedSections;

        // Update displayed sections
        setCurrentSections(
            sections.slice(startIndex, endIndex).map((section, index) => ({
                original: section,
                translated: translations[startIndex + index] || '',
            }))
        );
    }, [currentPage, filteredOriginalSections, filteredTranslatedSections, originalSections, translatedSections, sectionsPerPage]);





    // Updated function to highlight and ignore glossary terms based on ignore status
    const highlightGlossaryTerms = (text, terms) => {
        if (typeof text !== 'string') return '';

        const glossaryWords = terms.map(term => ({
            words: term.source.split(/\s+/),
            ignore: term.ignore
        }));
        const words = text.split(/\s+/);
        const highlightedWords = [];
        let i = 0;

        while (i < words.length) {
            let foundMatch = false;

            for (const { words: termWords, ignore } of glossaryWords) {
                if (words[i] === termWords[0]) { // Case-sensitive match
                    let isMatch = true;

                    for (let j = 0; j < termWords.length; j++) {
                        if (words[i + j] !== termWords[j]) {
                            isMatch = false;
                            break;
                        }
                    }

                    if (isMatch) {
                        const termText = termWords.join(' ');

                        highlightedWords.push(
                            `<span title="Glossary Term" style="background-color: #FFFACD; border-radius: 4px; padding: 2px 4px; margin: 0 2px;">${ignore ? `<x>${termText}</x>` : termText
                            }</span>`
                        );

                        i += termWords.length;
                        foundMatch = true;
                        break;
                    }
                }
            }

            if (!foundMatch) {
                highlightedWords.push(words[i]);
                i++;
            }
        }

        return highlightedWords.join(' ');
    };

    // Apply filters only once on initial render or whenever filter dependencies change
    useEffect(() => {
        applyFilters();
    }, [applyFilters]);

    const calculateCounts = useCallback((sections) => {
        let totalWords = 0;
        let totalChars = 0;
        sections.forEach((section) => {
            const words = section.split(/\s+/).filter(Boolean).length;
            totalWords += words;
            totalChars += section.length;
        });
        setWordCount(totalWords);
        setCharCount(totalChars);
    }, []);

    const calculateOverallProgress = useCallback((approvedSections) => {
        const totalSections = originalSections.length;
        const approvedCount = approvedSections.length;
        const newProgress = totalSections > 0 ? (approvedCount / totalSections) * 100 : 0;
        setOverallProgress(newProgress);
    }, [originalSections.length]);

    const scrollToBottom = () => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight; // Scroll to the bottom
        }
    };

    useEffect(() => {
        const fetchGlossaries = async () => {
            try {
                const response = await axios.get('/api/glossary', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setGlossaries(response.data);
                console.log("Fetched glossaries:", response.data);
            } catch (error) {
                console.error("Failed to load glossaries:", error);
            }
        };
        fetchGlossaries();
    }, [token]);

    // Glossary selection and placeholder replacement functions
    const handleGlossarySelection = async (glossaryId) => {
        setSelectedGlossaryId(glossaryId);
        if (glossaryId) {
            try {
                const response = await axios.get(`/api/glossary/${glossaryId}/terms`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setSelectedGlossaryTerms(response.data);
            } catch (error) {
                console.error("Failed to load glossary terms:", error);
            }
        } else {
            setSelectedGlossaryTerms([]);
        }
    };

    let ignoredTerms = []; // Array to store terms that are ignored

    const createPlaceholders = (text) => {
        let modifiedText = text;
        ignoredTerms = []; // Reset ignored terms for each translation

        selectedGlossaryTerms.forEach((term) => {
            if (term.ignore) {
                const regex = new RegExp(`\\b${term.source}\\b`, 'gi');
                if (regex.test(modifiedText)) {
                    ignoredTerms.push(term.source); // Log the term for later
                    modifiedText = modifiedText.replace(regex, '<x></x>'); // Replace with generic tag
                }
            }
        });

        return modifiedText;
    };

    const replacePlaceholdersWithTerms = (text) => {
        let modifiedText = text;
        let termIndex = 0; // Index to track terms in ignoredTerms array

        modifiedText = modifiedText.replace(/<x><\/x>/g, () => {
            // Replace each placeholder with the corresponding ignored term
            const term = ignoredTerms[termIndex];
            termIndex += 1; // Move to the next term for the next placeholder
            return term;
        });

        return modifiedText;
    };


    // Filter glossaries based on project languages
    useEffect(() => {
        if (glossaries.length > 0 && sourceLanguage && targetLanguage) {
            const matchingGlossaries = glossaries.filter(
                glossary =>
                    glossary.sourceLanguage.toLowerCase() === sourceLanguage.toLowerCase() &&
                    glossary.targetLanguage.toLowerCase() === targetLanguage.toLowerCase()
            );

            setFilteredGlossaries(matchingGlossaries);
        }
    }, [glossaries, sourceLanguage, targetLanguage]);

    useEffect(() => {
        scrollToBottom(); // Scroll to the bottom when messages change
    }, [message]); // Trigger whenever `message` state changes

    useEffect(() => {
        const fetchTeamMembers = async () => {
            try {
                const response = await axios.get('/api/team', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setTeamMembers(response.data);
            } catch (err) {
                console.error('Error fetching team members:', err);
            }
        };
        fetchTeamMembers();
    }, [token]);

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await axios.get(`/api/projects/${id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                const { project } = response.data;

                setOriginalSections(project.sections);
                setTranslatedSections(project.translatedSections || []);
                setConfidenceScores(project.confidenceScores || {}); // Initialize confidence scores
                setFilteredOriginalSections(project.sections);
                setFilteredTranslatedSections(project.translatedSections || []);
                setSourceLanguage(project.sourceLanguage);
                setTargetLanguage(project.targetLanguage);
                setProjectCreator(project.createdBy);
                setAssignedTo(project.assignedTo);
                setApprovedSections(project.approvedSections || []);
                calculateCounts(project.sections);
                calculateOverallProgress(project.approvedSections || []);
            } catch (err) {
                console.error('Error fetching project:', err);
            }
        };

        fetchProject();
    }, [id, token, calculateCounts, calculateOverallProgress]);


    // Fetch annotations for a specific section
    const fetchAnnotations = async (sectionIndex) => {
        try {
            const response = await axios.get(`/api/annotations/${id}/annotations/${sectionIndex}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setSelectedSectionAnnotations(response.data);
        } catch (error) {
            console.error('Error fetching annotations:', error);
        }
    };

    // Handle opening annotation panel for a section
    const handleAnnotationOpen = (index) => {
        setSelectedSectionIndex(index);
        setIsAnnotationPanelOpen(true);
        fetchAnnotations(index);
    };

    // Handle closing the annotation panel
    const handleAnnotationClose = () => {
        setIsAnnotationPanelOpen(false);
        setSelectedSectionAnnotations([]);
    };

    // Initialize the socket connection
    useEffect(() => {
        // Initialize the socket connection on component mount
        const newSocket = io('http://192.168.68.130:5002');  // Backend server URL
        setSocket(newSocket);

        // Fetch chat messages when the component loads
        const fetchMessages = async () => {
            try {
                const response = await axios.get(`/api/chat/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setMessage(response.data);  // Set fetched messages in state
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        fetchMessages();

        // Listen for incoming messages
        newSocket.on('receiveMessage', (data) => {
            console.log("Message received:", data);  // Debugging log
            setMessage((prevMessages) => [...prevMessages, data]);
        });

        // Clean up the socket connection on component unmount
        return () => {
            newSocket.close();
        };
    }, []);  // Empty dependency array ensures this runs only on mount/unmount

    // Join the chat room after currentUserId is available
    useEffect(() => {
        if (socket && currentUserId) {
            socket.emit('join', currentUserId);
            console.log(`User ${currentUserId} joined the chat room`);
        }
    }, [socket, currentUserId]);  // Run when both socket and userId are ready


    // Send message handler
    const handleSendMessage = () => {
        if (newMessage.trim()) {
            if (socket) {
                // Log the type and value of assignedTo and projectCreator for debugging
                console.log('AssignedTo:', assignedTo);  // Should be a string
                console.log('ProjectCreator:', typeof projectCreator, projectCreator);  // Should be an object

                // Extract the _id from projectCreator if it's an object
                const projectCreatorId = projectCreator && projectCreator._id ? projectCreator._id : projectCreator;

                // Since assignedTo is a string, compare directly
                const receiverId = currentUserId === assignedTo
                    ? projectCreatorId  // If current user is assignedTo, send to projectCreator
                    : assignedTo;  // Otherwise, send to assignedTo

                // Log the senderId and receiverId for debugging
                console.log(`Sending message from ${currentUserId} to ${receiverId}`);

                // Ensure receiverId is valid
                if (!receiverId) {
                    console.error('Invalid receiverId:', receiverId);
                    return;
                }

                // Prepare the message data
                const messageData = {
                    projectId: id,  // Project ID from params
                    senderId: { _id: currentUserId, avatarUrl: currentUserId.avatarUrl },  // Sender is the current user
                    receiverId,  // Correct receiver (project creator or assigned translator)
                    message: newMessage,
                };

                // Emit the message to the server
                socket.emit('sendMessage', messageData);

                // Add the message to local state for immediate feedback
                //setMessage([...message, messageData]);

                // Clear the input field after sending the message
                setNewMessage('');
            } else {
                console.error('Socket is not connected yet.');
            }
        }
    };

    const toggleApprovalForSections = (toggleAll) => {
        const sectionsToToggle = toggleAll ? filteredSections : currentSections;
        const updatedApprovedSections = new Set(approvedSections);

        sectionsToToggle.forEach((section, index) => {
            const globalIndex = toggleAll ? index : index + indexOfFirstSection;

            if (updatedApprovedSections.has(globalIndex)) {
                updatedApprovedSections.delete(globalIndex);  // Unapprove if already approved
            } else {
                updatedApprovedSections.add(globalIndex);  // Approve if not already approved
            }
        });

        setApprovedSections(Array.from(updatedApprovedSections));
        calculateOverallProgress(Array.from(updatedApprovedSections));
    };

    const toggleApproval = async (index) => {
        const globalIndex = index + indexOfFirstSection;
        const isApproved = approvedSections.includes(globalIndex);
        const updatedApproved = isApproved
            ? approvedSections.filter((i) => i !== globalIndex)
            : [...approvedSections, globalIndex];

        setApprovedSections(updatedApproved);
        calculateOverallProgress(updatedApproved);
        

        try {
            await axios.put(`/api/projects/${id}/toggle-approval`, {
                sectionIndex: globalIndex,
                approved: !isApproved,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } catch (err) {
            console.error('Error saving toggle state:', err);
        }
    };

    const handleSaveTranslation = async () => {
        setIsSaving(true); // Start showing the progress indicator
        setSaveProgress(0); // Reset progress to 0 at the start

        try {
            // Ensure the arrays have the correct length matching `originalSections`
            const totalSections = originalSections.length;

            // Ensure translatedSections and approvedSections have correct lengths
            const formattedTranslatedSections = Array.from({ length: totalSections }, (_, index) =>
                translatedSections[index] || ''
            );

            const formattedApprovedSections = approvedSections.filter(
                (index) => index < totalSections
            );

            // Ensure confidenceScores is an array of the correct length
            const formattedConfidenceScores = Array.from({ length: totalSections }, (_, index) =>
                confidenceScores[index] || 0
            );

            // Log the payload before sending for debugging
            console.log({
                translatedSections: formattedTranslatedSections,
                approvedSections: formattedApprovedSections,
                confidenceScores: formattedConfidenceScores,
            });

            // Send data to the backend
            await axios.put(
                `/api/projects/${id}/translate`,
                {
                    translatedSections: formattedTranslatedSections,
                    approvedSections: formattedApprovedSections,
                    confidenceScores: formattedConfidenceScores,
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            alert('Translation saved successfully!');
        } catch (err) {
            console.error('Error saving translation:', err);
            alert('Error saving translation.');
        } finally {
            setIsSaving(false); // Hide the progress indicator when done
            setSaveProgress(0); // Reset progress when saving is complete
        }
    };








    const handleTranslate = async (index) => {
        const originalText = originalSections[index + indexOfFirstSection];

        if (!translationService) {
            alert('Please select a translation service.');
            return;
        }

        // Check if the originalText matches a glossary term with ignore status
        const exactGlossaryTerm = selectedGlossaryTerms.find(
            (term) => term.source === originalText && term.ignore
        );

        if (exactGlossaryTerm) {
            // Use glossary source term with <x> tags if marked as ignored
            const taggedText = `<x>${exactGlossaryTerm.source}</x>`;
            handleTranslationChange(index, taggedText);
            console.log(`Translation source: ignored glossary term | Original Text: "${originalText}" | Tagged Text: "${taggedText}"`);
            return;
        }

        // Proceed with creating placeholders if a glossary is selected
        const textWithPlaceholders = selectedGlossaryId ? createPlaceholders(originalText) : originalText;

        // Ensure source language is set to 'en' if it's 'en-gb'
        const sourceLang = sourceLanguage === 'en-gb' ? 'en' : sourceLanguage;

        // If not found in memory, use the translation API
        try {
            const response = await axios.post(
                `/api/translate`,
                {
                    text: textWithPlaceholders,
                    sourceLanguage: sourceLang,
                    targetLanguage,
                    service: translationService,
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            let apiTranslatedText = response.data.translatedText;

            // Replace <x></x> tags with actual glossary terms
            selectedGlossaryTerms.forEach((term) => {
                if (term.ignore) {
                    const tagPattern = new RegExp(`<x>${term.source}</x>`, 'gi');
                    apiTranslatedText = apiTranslatedText.replace(tagPattern, term.source);
                }
            });

            const finalTranslatedText = selectedGlossaryId
                ? replacePlaceholdersWithTerms(apiTranslatedText)
                : apiTranslatedText;

            handleTranslationChange(index, finalTranslatedText);

            console.log(`Translation source: AI | Original Text: "${originalText}" | Translated Text: "${finalTranslatedText}"`);

            // Save the translation to memory if enabled
            if (useTranslationMemory) {
                try {
                    await axios.post('/api/translation-memory/save', {
                        userId: currentUserId,
                        originalText,
                        translatedText: finalTranslatedText,
                        sourceLanguage: sourceLang,
                        targetLanguage,
                    });
                    console.log(`Translation saved to memory for text: "${originalText}"`);
                } catch (err) {
                    console.error('Error saving translation to memory:', err);
                }
            }
        } catch (err) {
            console.error('Error translating text:', err);
            alert('Error translating text.');
        }
    };










    const handleTranslationChange = async (index, value, confidence = 100) => {
        const globalIndex = index + indexOfFirstSection;
        const originalText = originalSections[globalIndex];
        const translatedText = value;

        // Update the translated sections with the new value
        const updatedTranslations = [...translatedSections];
        updatedTranslations[globalIndex] = translatedText;

        // Apply glossary highlights to the translated text
        const highlightedText = highlightGlossaryTerms(translatedText, selectedGlossaryTerms);
        updatedTranslations[globalIndex] = highlightedText;
        setTranslatedSections(updatedTranslations);

        // Update the confidence score for the current section
        setConfidenceScores((prevScores) => ({
            ...prevScores,
            [globalIndex]: confidence,
        }));

        // Save the updated translation and confidence score in the backend
        try {
            await axios.put(`/api/projects/${id}/translate`, {
                translatedSections: updatedTranslations,
                confidenceScores: {
                    ...confidenceScores,
                    [globalIndex]: confidence, // Update the confidence score
                },
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(`Translation and confidence score saved for section ${globalIndex}`);
        } catch (err) {
            console.error('Error saving translation and confidence score:', err);
        }

        // Save to translation memory if enabled
        if (useTranslationMemory) {
            try {
                await axios.post('/api/translation-memory/save', {
                    userId: currentUserId,
                    originalText,
                    translatedText,
                    sourceLanguage,
                    targetLanguage,
                });
                console.log(`Translation saved to memory for text: "${originalText}"`);
            } catch (err) {
                console.error('Error saving translation to memory:', err);
            }
        }
    };




    const handleAssignTranslator = async (translatorId) => {
        try {
            if (!translatorId) {
                alert('Please select a translator.');
                return;
            }

            // If 'unassign' is selected, it will unassign the translator
            const isUnassign = translatorId === 'unassign';

            await axios.put(`/api/projects/${id}/assign-translator`, {
                translatorId: isUnassign ? null : translatorId, // Set to null if unassigning
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            alert(isUnassign ? 'Translator unassigned successfully!' : 'Translator updated successfully!');

            // If unassigning, update the state to reflect that no translator is assigned
            setAssignedTo(isUnassign ? null : translatorId);

        } catch (err) {
            console.error('Error assigning translator:', err);
            alert('Error assigning translator.');
        }
    };


    const handleTranslatorChange = (e) => {
        const selectedTranslatorId = e.target.value;
        handleAssignTranslator(selectedTranslatorId);
    };

    const handleAutoTranslate = async () => {
        const sectionsToTranslate = filteredOriginalSections.length > 0 ? filteredOriginalSections : originalSections;

        if (!translationService) {
            alert('Please select a translation service.');
            return;
        }

        setIsAutoTranslating(true);
        setAutoTranslateProgress(0);

        const totalSections = sectionsToTranslate.length;
        console.log(`Starting auto-translate for ${totalSections} sections`);

        // Local copies of state
        const updatedTranslations = [...translatedSections]; // Avoid state updates during processing
        const updatedConfidenceScores = { ...confidenceScores };

        try {
            // Loop through all sections and process translations
            for (let index = 0; index < totalSections; index++) {
                const originalText = sectionsToTranslate[index];
                const globalIndex = filteredSections.length > 0 ? originalSections.indexOf(originalText) : index;

                console.log(`Processing section ${index + 1}/${totalSections}: ${originalText}`);

                // Skip if already translated
                if (updatedTranslations[globalIndex]) {
                    console.log(`Skipping section ${index + 1}: Already translated`);
                    continue;
                }

                let translatedText = '';
                let confidenceScore = 0;

                // Ensure source language is set to 'en' if it's 'en-gb'
                const sourceLang = sourceLanguage === 'en-gb' ? 'en' : sourceLanguage;

                // Check translation memory
                if (useTranslationMemory) {
                    try {
                        const cachedTranslation = await axios.post(
                            '/api/translation-memory/check',
                            {
                                userId: currentUserId,
                                originalText,
                                sourceLanguage: sourceLang,
                                targetLanguage,
                            },
                            {
                                params: { algorithm: preferredAlgorithm },
                                headers: { Authorization: `Bearer ${token}` },
                            }
                        );

                        if (cachedTranslation.data.translatedText) {
                            translatedText = cachedTranslation.data.translatedText;
                            confidenceScore = cachedTranslation.data.confidenceScore || 0;
                            console.log(`Memory hit: ${originalText} -> ${translatedText}`);
                        }
                    } catch (err) {
                        console.error('Error checking translation memory:', err);
                    }
                }

                // Fallback to translation service if not found in memory
                if (!translatedText) {
                    try {
                        const response = await axios.post(
                            `/api/translate`,
                            {
                                text: originalText,
                                sourceLanguage: sourceLang,
                                targetLanguage,
                                service: translationService,
                            },
                            {
                                headers: { Authorization: `Bearer ${token}` },
                            }
                        );

                        translatedText = response.data.translatedText;
                        confidenceScore = 100; // Default confidence for AI translations
                        console.log(`AI Translation: ${originalText} -> ${translatedText}`);

                        // Save the translation to memory
                        if (useTranslationMemory) {
                            try {
                                await axios.post('/api/translation-memory/save', {
                                    userId: currentUserId,
                                    originalText,
                                    translatedText,
                                    sourceLanguage: sourceLang,
                                    targetLanguage,
                                });
                                console.log(`Saved to memory: ${originalText} -> ${translatedText}`);
                            } catch (err) {
                                console.error('Error saving translation to memory:', err);
                            }
                        }
                    } catch (err) {
                        console.error('Error translating text:', err);
                        alert('Error translating text. Please try again.');
                        break;
                    }
                }

                // Update local copies with the translation
                updatedTranslations[globalIndex] = translatedText;
                updatedConfidenceScores[globalIndex] = confidenceScore;

                // Update progress
                const progress = ((index + 1) / totalSections) * 100;
                setAutoTranslateProgress(progress);
                console.log(`Progress: ${progress.toFixed(2)}%`);
            }

            // Save translations after processing all sections
            await axios.put(
                `/api/projects/${id}/translate`,
                {
                    translatedSections: updatedTranslations,
                    confidenceScores: Object.values(updatedConfidenceScores),
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            console.log('Translations saved successfully');
            alert('Auto-translate completed successfully!');
        } catch (error) {
            console.error('Error during auto-translate:', error);
            alert('Error during auto-translate. Please try again.');
        } finally {
            setTranslatedSections(updatedTranslations); // Update state to reflect saved data
            setConfidenceScores(updatedConfidenceScores);
            setIsAutoTranslating(false);
            setAutoTranslateProgress(0);
        }
    };










    useEffect(() => {
        // Play sound when a new message is received, but not for the current user's message
        const lastMessage = message[message.length - 1];
        if (lastMessage && lastMessage.senderId._id !== currentUserId) {
            audioRef.current.play(); // Play the notification sound
        }
    }, [message, currentUserId]);

    const calculateTranslationProgress = useCallback(() => {
        const totalSections = originalSections.length;
        const translatedCount = translatedSections.filter(section => section).length;
        return totalSections > 0 ? (translatedCount / totalSections) * 100 : 0;
    }, [originalSections, translatedSections]);

    const translationProgress = calculateTranslationProgress();

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopied(true); // Show feedback
        });
    };

    const determineSpeedDialDirection = (index) => {
        const rect = rowRef.current[index]?.getBoundingClientRect();
        if (rect) {
            setSpeedDialDirection(rect.bottom > window.innerHeight - 200 ? 'up' : 'down');
        }
    };

    return (
        <Container maxWidth="lg" sx={{ marginTop: '50px' }}>
            {/* Top Section with Source/Target Language, Progress, and Word/Char Count */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                {/* Source and Target Languages with Circle Flags and Arrow */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {/* Source Language */}
                    <CircleFlag
                        countryCode={getLanguageDetails(sourceLanguage).countryCode.toLowerCase()}
                        height="30"
                    />
                    <Typography variant="h6">
                        {getLanguageDetails(sourceLanguage).name || sourceLanguage}
                    </Typography>

                    <ArrowForwardIcon />

                    {/* Target Language */}
                    <Typography variant="h6">
                        {getLanguageDetails(targetLanguage).name || targetLanguage}
                    </Typography>
                    <CircleFlag
                        countryCode={getLanguageDetails(targetLanguage).countryCode.toLowerCase()}
                        height="30"
                    />
                </Box>

                {/* Translation and QA Progress Inline */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    {/* Translation Progress */}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                            Translation Progress:
                        </Typography>
                        <Box sx={{ width: '100px' }}>
                            <LinearProgress variant="determinate" value={translationProgress} sx={{ height: 8, borderRadius: 1 }} />
                        </Box>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            {translationProgress.toFixed(2)}%
                        </Typography>
                    </Box>

                    {/* Overall QA Progress */}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                            Overall QA Progress:
                        </Typography>
                        <Box sx={{ width: '100px' }}>
                            <LinearProgress variant="determinate" value={overallProgress} sx={{ height: 8, borderRadius: 1 }} />
                        </Box>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            {overallProgress.toFixed(2)}%
                        </Typography>
                    </Box>
                </Box>

                {/* Word and Character Count */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography variant="body2">Words: {wordCount}</Typography>
                    <Typography variant="body2">Characters: {charCount}</Typography>
                </Box>
            </Box>

            

            {/* Only show if the user is the project creator */}
            {(typeof projectCreator === 'object' ? projectCreator._id : projectCreator?.toString()) === currentUserId?.toString() && (
                <>
                    {console.log("User is the project creator, showing additional features")}

                    {/* Inline Selection Row */}
                    <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                        {/* Select Translator */}
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel>Select Translator</InputLabel>
                                <Select
                                    value={assignedTo || 'unassign'}
                                    onChange={handleTranslatorChange}
                                    fullWidth
                                >
                                    <MenuItem value="unassign">Unassign Translator</MenuItem>
                                    {teamMembers.map((member) => (
                                        <MenuItem key={member.userId._id} value={member.userId._id}>
                                            {member.userId.email}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Select Glossary */}
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel>Select Glossary</InputLabel>
                                <Select
                                    value={selectedGlossaryId || ''}
                                    onChange={(e) => handleGlossarySelection(e.target.value)}
                                >
                                    {filteredGlossaries.map((glossary) => (
                                        <MenuItem key={glossary._id} value={glossary._id}>
                                            {glossary.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Select Translation Service */}
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel>Select Translation Service</InputLabel>
                                <Select
                                    value={translationService}
                                    onChange={(e) => setTranslationService(e.target.value)}
                                    fullWidth
                                >
                                    <MenuItem value="deepl">DeepL</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {/* Translation Memory Checkbox */}
                    <Box sx={{ marginBottom: '20px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={useTranslationMemory}
                                    onChange={(e) => setUseTranslationMemory(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label="Use Translation Memory"
                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                        <FormControlLabel
                            control={<Checkbox checked={filterNumbers} onChange={(e) => setFilterNumbers(e.target.checked)} />}
                            label="Exclude Numbers Only"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={filterSymbols} onChange={(e) => setFilterSymbols(e.target.checked)} />}
                            label="Exclude Symbols Only"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={filterNumbersAndSymbols} onChange={(e) => setFilterNumbersAndSymbols(e.target.checked)} />}
                            label="Exclude Numbers and Symbols"
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '20px' }}>
                        {isAutoTranslating && (
                            <Box sx={{ width: '50%', marginRight: 2 }}>
                                <LinearProgress variant="determinate" value={autoTranslateProgress} />
                                <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center' }}>
                                    {autoTranslateProgress.toFixed(2)}% completed
                                </Typography>
                            </Box>
                        )}

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAutoTranslate}
                            disabled={isAutoTranslating}
                        >
                            Auto Translate
                        </Button>
                    </Box>

                </>
            )}

            <Box sx={{ display: 'flex', gap: 4, marginBottom: '20px' }}>
                {/* Overall Progress Section */}
                <Box sx={{ flexGrow: 1 }}>
                    

                    {/* Toggle All Sections Button */}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => toggleApprovalForSections(true)}  // Toggles all sections
                        sx={{marginTop: 1 }}
                    >
                        Toggle All Sections
                    </Button>
                </Box>
            </Box>
            <Grid container spacing={4}>
                {currentSections.map((sectionPair, index) => (
                    <Grid item xs={12} key={index}>
                        <Box
                            ref={(el) => (rowRef.current[index] = el)}
                            onMouseEnter={() => {
                                setHoveredIndex(index);
                                determineSpeedDialDirection(index); // Set direction based on position
                            }}
                            onMouseLeave={() => setHoveredIndex(null)}
                            sx={{
                                position: 'relative',
                                paddingY: 1,
                            }}
                        >
                            <Grid container spacing={2} alignItems="flex-start">
                                {/* Segment Number Column */}
                                <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60px', position: 'relative' }}>
                                    <Typography variant="body2">{index + 1 + indexOfFirstSection}</Typography>

                                    {/* Annotation Indicator Icon */}
                                    {annotatedSections.has(index + indexOfFirstSection) && (
                                        <Tooltip title="This section has a note">
                                            <IconButton size="small" color="secondary" sx={{ position: 'absolute', bottom: 0, right: -20 }}>
                                                <NoteIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Grid>

                                {/* Original Text Column */}
                                <Grid item xs>
                                    <TextField
                                        label="Original Text"
                                        multiline
                                        fullWidth
                                        value={sectionPair.original}
                                        InputProps={{ readOnly: true }}
                                        variant="outlined"
                                    />
                                </Grid>

                                {/* Translated Text Column */}
                                <Grid item xs>
                                    <TextField
                                        label="Translated Text"
                                        multiline
                                        fullWidth
                                        value={translatedSections[index + indexOfFirstSection] || ''}
                                        onChange={(e) => {
                                            const updatedText = e.target.value;
                                            const globalIndex = index + indexOfFirstSection;

                                            setTranslatedSections((prev) => {
                                                const updated = [...prev];
                                                updated[globalIndex] = updatedText;
                                                return updated;
                                            });
                                        }}
                                        variant="outlined"
                                        style={{
                                            backgroundColor: approvedSections.includes(index + indexOfFirstSection) ? '#f0f0f0' : 'white',
                                        }}
                                        disabled={approvedSections.includes(index + indexOfFirstSection)}
                                    />
                                </Grid>




                                {/* Action Buttons Column with Speed Dial */}
                                <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100px' }}>
                                    {hoveredIndex === index && (
                                        <SpeedDial
                                            ariaLabel="Actions"
                                            icon={<SpeedDialIcon />}
                                            direction={speedDialDirection}
                                            sx={{
                                                position: 'absolute',
                                                top: speedDialDirection === 'down' ? 0 : 'auto',
                                                bottom: speedDialDirection === 'up' ? 0 : 'auto',
                                                right: 0,
                                                zIndex: 1500,
                                            }}
                                        >
                                            {/* Approve/Unapprove Action */}
                                            <SpeedDialAction
                                                icon={approvedSections.includes(index + indexOfFirstSection) ? <UndoIcon /> : <CheckCircleIcon />}
                                                tooltipTitle={approvedSections.includes(index + indexOfFirstSection) ? 'Unapprove' : 'Approve'}
                                                onClick={() => toggleApproval(index)}
                                            />

                                            {/* Translate Action (only for project creator) */}
                                            {(typeof projectCreator === 'object' ? projectCreator._id : projectCreator?.toString()) === currentUserId?.toString() && (
                                                <SpeedDialAction
                                                    icon={<TranslateIcon />}
                                                    tooltipTitle="Translate"
                                                    onClick={() => handleTranslate(index)}
                                                />
                                            )}

                                            {/* Annotation Action */}
                                            <SpeedDialAction
                                                icon={<AddCommentIcon />}
                                                tooltipTitle="Add Annotation"
                                                onClick={() => handleAnnotationOpen(index + indexOfFirstSection)}
                                            />

                                            {/* Copy to Clipboard Action */}
                                            <SpeedDialAction
                                                icon={<ContentCopyIcon />}
                                                tooltipTitle="Copy to Clipboard"
                                                onClick={() => handleCopyToClipboard(filteredTranslatedSections[index + indexOfFirstSection] || '')}
                                            />

                                            {/* Add Glossary Term Action */}
                                            <SpeedDialAction
                                                icon={<LibraryBooksIcon />}
                                                tooltipTitle="Add Glossary Term"
                                                onClick={() => handleAddGlossaryTerm(index + indexOfFirstSection)} // Pass global index
                                            />

                                        </SpeedDial>
                                    )}
                                </Grid>
                                




                            </Grid>
                        </Box>
                    </Grid>
                ))}

                {/* Snackbar for Copy Feedback */}
                <Snackbar
                    open={copied}
                    autoHideDuration={2000}
                    onClose={() => setCopied(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={() => setCopied(false)} severity="success" sx={{ width: '100%' }}>
                        Text copied to clipboard!
                    </Alert>
                </Snackbar>
            </Grid>

            {/* Glossary Term Popup */}
            <Modal open={openGlossaryDialog} onClose={handleCloseGlossaryDialog}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 3,
                        borderRadius: 2,
                    }}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Typography variant="h6">Add Glossary Term</Typography>
                        <IconButton onClick={handleCloseGlossaryDialog}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Source Term"
                        type="text"
                        fullWidth
                        value={newGlossaryTerm.source}
                        onChange={(e) => setNewGlossaryTerm({ ...newGlossaryTerm, source: e.target.value })}
                        sx={{ marginBottom: 2 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={newGlossaryTerm.ignore}
                                onChange={(e) =>
                                    setNewGlossaryTerm({
                                        ...newGlossaryTerm,
                                        ignore: e.target.checked,
                                        target: e.target.checked ? '' : newGlossaryTerm.target,
                                    })
                                }
                                color="primary"
                            />
                        }
                        label="Ignore Term"
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        margin="dense"
                        label="Target Term"
                        type="text"
                        fullWidth
                        value={newGlossaryTerm.target}
                        onChange={(e) => setNewGlossaryTerm({ ...newGlossaryTerm, target: e.target.value })}
                        disabled={newGlossaryTerm.ignore}
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        margin="dense"
                        label="Context"
                        type="text"
                        fullWidth
                        value={newGlossaryTerm.context}
                        onChange={(e) => setNewGlossaryTerm({ ...newGlossaryTerm, context: e.target.value })}
                        placeholder="Optional usage information"
                        sx={{ marginBottom: 2 }}
                    />
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleSubmitGlossaryTerm}
                        disabled={!newGlossaryTerm.source.trim()}
                    >
                        Submit
                    </Button>
                </Box>
            </Modal>

            <AnnotationPanel
                projectId={projectId}
                sectionIndex={selectedSectionIndex}
                open={isAnnotationPanelOpen}
                onClose={handleAnnotationClose}
                onAnnotationAdded={handleAnnotationAdded}
            />

            <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
                {/* Pagination */}
                <Pagination
                    count={Math.ceil(
                        (filteredOriginalSections.length > 0 ? filteredOriginalSections.length : originalSections.length) /
                        sectionsPerPage
                    )}
                    page={currentPage}
                    onChange={(_event, value) => setCurrentPage(value)}
                />
            </Box>

            <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveTranslation}
                    disabled={isSaving} // Disable button while saving
                    fullWidth
                    sx={{ width: '200px', margin: '0 auto' }}
                >
                    Save Translations
                </Button>

                {isSaving && (
                    <Box sx={{ width: '200px', margin: '10px auto', textAlign: 'center' }}>
                        <LinearProgress
                            variant="determinate"
                            value={saveProgress}
                            sx={{ height: 6, borderRadius: 3 }}
                        />
                        <Typography variant="body2" sx={{ marginTop: '5px' }}>
                            {saveProgress.toFixed(0)}%
                        </Typography>
                    </Box>
                )}
            </Box>





            {/* Chat Box */}
            {assignedTo ? (
                <Box sx={{ marginTop: '40px' }}>
                    <Typography variant="h6">Chat</Typography>
                    <Box
                        ref={chatBoxRef}
                        sx={{
                            border: '1px solid #ccc',
                            borderRadius: '10px',
                            padding: '20px',
                            height: '300px',
                            overflowY: 'auto',
                            marginBottom: '20px',
                            scrollbarWidth: 'none',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}
                    >
                        {message.map((msg, index) => {
                            const isCurrentUser = msg.senderId._id === currentUserId;

                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: isCurrentUser ? 'flex-end' : 'flex-start',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {!isCurrentUser && (
                                        <Box sx={{ marginRight: '10px' }}>
                                            {msg.senderId.avatarUrl ? (
                                                <img
                                                    src={`http://192.168.68.130:5002${msg.senderId.avatarUrl}`}
                                                    alt="avatar"
                                                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                                                />
                                            ) : (
                                                <Box
                                                    sx={{
                                                        width: '40px',
                                                        height: '40px',
                                                        borderRadius: '50%',
                                                        backgroundColor: '#ccc',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        color: '#fff',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {msg.senderId.firstName.charAt(0)}
                                                    {msg.senderId.lastName.charAt(0)}
                                                </Box>
                                            )}
                                        </Box>
                                    )}

                                    <Box
                                        sx={{
                                            backgroundColor: isCurrentUser ? '#e0f7fa' : '#f5f5f5',
                                            borderRadius: '15px',
                                            padding: '10px',
                                            maxWidth: '70%',
                                        }}
                                    >
                                        <Typography>{msg.message}</Typography>
                                    </Box>

                                    {isCurrentUser && (
                                        <Box sx={{ marginLeft: '10px' }}>
                                            {msg.senderId.avatarUrl ? (
                                                <img
                                                    src={`http://192.168.68.130:5002${msg.senderId.avatarUrl}`}
                                                    alt="avatar"
                                                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                                                />
                                            ) : (
                                                <Box
                                                    sx={{
                                                        width: '40px',
                                                        height: '40px',
                                                        borderRadius: '50%',
                                                        backgroundColor: '#ccc',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        color: '#fff',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {msg.senderId.firstName.charAt(0)}
                                                    {msg.senderId.lastName.charAt(0)}
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            );
                        })}
                    </Box>

                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Type your message..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        color="primary"
                                        onClick={handleSendMessage}
                                    >
                                        <SendIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            ) : (
                <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
                    <Typography variant="h6" color="textSecondary">
                        Chat feature is not available for this project.
                    </Typography>
                </Box>
            )}
        </Container>
    );
};

export default TranslateProject;
