import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
    Container, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Button, Box, TextField, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Alert,
    Breadcrumbs, Link, Checkbox, FormControlLabel, TablePagination, IconButton, Tooltip
} from '@mui/material';
import { useSelector } from 'react-redux';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const GlossaryTerms = () => {
    const { glossaryId } = useParams();
    const token = useSelector((state) => state.auth.token);
    const [terms, setTerms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [newTerm, setNewTerm] = useState({ source: '', target: '', context: '', ignore: false });
    const [editingTerm, setEditingTerm] = useState(null);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    


    useEffect(() => {
        const fetchTerms = async () => {
            try {
                const response = await axios.get(`/api/glossary/${glossaryId}/terms`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setTerms(response.data);
            } catch (err) {
                setError('Failed to load glossary terms.');
            } finally {
                setLoading(false);
            }
        };

        fetchTerms();
    }, [glossaryId, token]);

    const handleDeleteTerm = async (termId) => {
        try {
            const response = await axios.delete(`/api/glossary/${glossaryId}/terms/${termId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.status === 200) {
                alert('Term deleted successfully!');
                // Update the terms state to remove the deleted term
                setTerms((prevTerms) => prevTerms.filter((term) => term._id !== termId));
            }
        } catch (error) {
            console.error('Failed to delete term:', error);
            alert('Error deleting term. Please try again.');
        }
    };


    const handleApproveTerm = async (termId) => {
        try {
            const response = await axios.put(
                `/api/glossary/${glossaryId}/terms/${termId}`,
                { approved: true },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            const updatedTerm = response.data;

            setTerms((prevTerms) =>
                prevTerms.map((term) => (term._id === termId ? updatedTerm : term))
            );
        } catch (error) {
            console.error('Error approving term:', error);
            setError('Failed to approve term.');
        }
    };


    const handleAddTerm = async () => {
        if (!newTerm.source) {
            setError('Please provide a source term.');
            return;
        }

        try {
            let updatedTerm;

            // Ensure `approved` flag is explicitly set to true
            const termPayload = {
                ...newTerm,
                approved: true, // Always set approved to true
            };

            if (editingTerm) {
                // Editing an existing term
                const response = await axios.put(`/api/glossary/${glossaryId}/terms/${editingTerm._id}`, termPayload, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                updatedTerm = response.data;

                // Update terms list with the edited term
                setTerms(terms.map((term) => (term._id === editingTerm._id ? updatedTerm : term)));
            } else {
                // Adding a new term
                const response = await axios.post(`/api/glossary/${glossaryId}/terms`, termPayload, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                updatedTerm = response.data;

                // Append the new term to the terms list
                setTerms([...terms, updatedTerm]);
            }

            // Reset term input and state
            setNewTerm({ source: '', target: '', context: '', ignore: false, approved: true });
            setEditingTerm(null);
            setOpenDialog(false);
            setError(''); // Clear any previous error
        } catch (err) {
            console.error('Error adding/editing term:', err);
            setError('Failed to add/edit term.');
        }
    };



    const handleEditClick = (term) => {
        setEditingTerm(term);
        setNewTerm(term);
        setOpenDialog(true);
    };

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredTerms = terms.filter((term) =>
        term.source.toLowerCase().includes(search.toLowerCase()) ||
        term.target.toLowerCase().includes(search.toLowerCase())
    );

    const pendingTerms = filteredTerms.filter((term) => !term.approved);

    if (loading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Container maxWidth="md" sx={{ marginTop: '50px' }}>
            {/* Breadcrumbs */}
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '20px' }}>
                <Link component={RouterLink} to="/dashboard/language-settings" color="inherit">
                    Language Settings
                </Link>
                <Typography color="textPrimary">Glossary Terms</Typography>
            </Breadcrumbs>

            <Typography variant="h4" gutterBottom>Glossary Terms</Typography>

            {/* Add New Term Section */}
            <Paper elevation={3} sx={{ padding: '20px', marginBottom: '20px' }}>
                <Typography variant="h6">Add a New Term</Typography>
                <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="Source Term"
                        value={newTerm.source}
                        onChange={(e) => setNewTerm({ ...newTerm, source: e.target.value })}
                        fullWidth
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={newTerm.ignore}
                                onChange={(e) =>
                                    setNewTerm({ ...newTerm, ignore: e.target.checked, target: e.target.checked ? '' : newTerm.target })
                                }
                                color="primary"
                            />
                        }
                        label="Ignore Term"
                    />
                    <TextField
                        label="Target Term"
                        value={newTerm.target}
                        onChange={(e) => setNewTerm({ ...newTerm, target: e.target.value })}
                        fullWidth
                        disabled={newTerm.ignore} // Disable if ignore is checked
                    />
                    <TextField
                        label="Context"
                        value={newTerm.context}
                        onChange={(e) => setNewTerm({ ...newTerm, context: e.target.value })}
                        fullWidth
                        placeholder="Optional information about the term's usage"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenDialog(true)}
                        sx={{ alignSelf: 'flex-start' }}
                    >
                        Add Term
                    </Button>
                </Box>
            </Paper>

            {/* Glossary Terms Table */}
            <Typography variant="h6" gutterBottom>Glossary Terms</Typography>
            <Paper elevation={3} sx={{ padding: '20px', marginBottom: '20px' }}>
                <TextField
                    label="Search Terms"
                    value={search}
                    onChange={handleSearchChange}
                    fullWidth
                    sx={{ marginBottom: 2 }}
                />
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Source Term</TableCell>
                                <TableCell>Target Term</TableCell>
                                <TableCell>Context</TableCell>
                                <TableCell>Ignore</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredTerms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => (
                                <TableRow key={term._id}>
                                    <TableCell>{term.source}</TableCell>
                                    <TableCell>{term.target}</TableCell>
                                    <TableCell>{term.context}</TableCell>
                                    <TableCell>{term.ignore ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>
                                        <Tooltip title="Edit Term">
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleEditClick(term)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete Term">
                                            <IconButton
                                                color="error"
                                                onClick={() => handleDeleteTerm(term._id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={filteredTerms.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>



            {/* Pending Terms Section */}
            <Typography variant="h6" gutterBottom>Terms Pending Approval</Typography>
            <Paper elevation={3} sx={{ padding: '20px', marginBottom: '20px' }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Source Term</TableCell>
                                <TableCell>Target Term</TableCell>
                                <TableCell>Context</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pendingTerms.length > 0 ? (
                                pendingTerms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((term) => (
                                    <TableRow key={term._id}>
                                        <TableCell>{term.source}</TableCell>
                                        <TableCell>{term.target}</TableCell>
                                        <TableCell>{term.context}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleApproveTerm(term._id)} // Approve functionality
                                                sx={{ marginRight: 1 }}
                                            >
                                                Approve
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">
                                        No terms pending approval.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={pendingTerms.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

            </Paper>

            {/* Add/Edit Dialog */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>{editingTerm ? 'Edit Term' : 'Add New Term'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Source Term"
                        type="text"
                        fullWidth
                        value={newTerm.source}
                        onChange={(e) => setNewTerm({ ...newTerm, source: e.target.value })}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={newTerm.ignore}
                                onChange={(e) =>
                                    setNewTerm({ ...newTerm, ignore: e.target.checked, target: e.target.checked ? '' : newTerm.target })
                                }
                                color="primary"
                            />
                        }
                        label="Ignore Term"
                    />
                    <TextField
                        margin="dense"
                        label="Target Term"
                        type="text"
                        fullWidth
                        value={newTerm.target}
                        onChange={(e) => setNewTerm({ ...newTerm, target: e.target.value })}
                        disabled={newTerm.ignore}
                    />
                    <TextField
                        margin="dense"
                        label="Context"
                        type="text"
                        fullWidth
                        value={newTerm.context}
                        onChange={(e) => setNewTerm({ ...newTerm, context: e.target.value })}
                        placeholder="Optional usage information"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">Cancel</Button>
                    <Button onClick={handleAddTerm} color="primary">
                        {editingTerm ? 'Save Changes' : 'Add Term'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default GlossaryTerms;
